/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import {graphql, useStaticQuery} from "gatsby"
import defaultOpenGraphImage from '../../content/assets/profile-pic.jpg'

function SEO({description, lang, meta, title, location, image}) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description
    const rootPath = `${__PATH_PREFIX__}/`

    const titleTemplate =  location.pathname === rootPath ? `%s` : `%s - ${site.siteMetadata.title}`;
    const ogImageUrl = `${site.siteMetadata.siteUrl}${(image || defaultOpenGraphImage)}`;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={titleTemplate}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: ogImageUrl,
                },
                {
                    property: `twitter:image`,
                    content: ogImageUrl,
                },
                {
                    property: `image`,
                    content: ogImageUrl,
                },
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
