import styled from 'styled-components';
import {Link as GatsbyLink} from "gatsby";

export const Link = styled.a`
  color: #ff365d;
  font-weight: bold;
`;

export const BlogPostLink = styled(GatsbyLink)`
  box-shadow: none;
  color: #ff365d;
  font-weight: 900;
  font-size: 1.75rem;
  font-family: Montserrat, sans-serif;
  margin-top: 0;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;
