import React from "react"
import {Link} from "gatsby"
import './layout.css';
import {rhythm, scale} from "../utils/typography"
import * as S from './styled';

class Layout extends React.Component {
    render() {
        const {location, title, children} = this.props
        const rootPath = `${__PATH_PREFIX__}/`
        let header

        if (location.pathname === rootPath) {
            header = (
                <h1
                    style={{
                        ...scale(1),
                        marginBottom: rhythm(1.5),
                        marginTop: 0,
                    }}
                >
                    <Link
                        style={{
                            boxShadow: `none`,
                            textDecoration: `none`,
                            color: `inherit`,
                        }}
                        to={`/`}
                    >
                        {title}
                    </Link>
                </h1>
            )
        } else {
            header = (
                <h3
                    style={{
                        fontFamily: `Montserrat, sans-serif`,
                        marginTop: 0,
                    }}
                >
                    <Link
                        style={{
                            boxShadow: `none`,
                            textDecoration: `none`,
                            color: `inherit`,
                        }}
                        to={`/`}
                    >
                        {title}
                    </Link>
                </h3>
            )
        }
        return (
            <div
                style={{
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    maxWidth: rhythm(24),
                    padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                }}
            >
                <header>{header}</header>
                <main>{children}</main>
                <S.Footer>
                    <span>© {new Date().getFullYear()} Spolsky</span>
                    <div>
                        <S.Link href="https://www.instagram.com/tata_inaczej/">instagram</S.Link> • <S.Link href="https://twitter.com/iamspolsky">twitter</S.Link> • <S.Link
                        href="https://github.com/Gustu">github</S.Link>
                    </div>
                </S.Footer>
            </div>
        )
    }
}

export default Layout
